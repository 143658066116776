<template>
  <div class="content_tt">
    <div class="tcheaderCT">
      <div>
        <image src="../../static/pf.jpg" style="width:80Px;" mode="widthFix"/>
        <div>Tadio</div>
      </div>
      <div>
        <div class="ty1">Valor do deposlto</div>
        <div class="ty"><span>R$</span>20.00</div>
      </div>
    </div>
<!--    <div class="tcheader">-->
<!--      <div>Por favor o rome CPF do pagedof</div>-->
<!--      <div class="sxc">-->
<!--        <image src="../../static/siyc.png" style="width:100Px;" mode="widthFix"/>-->
<!--        <div>PIX</div>-->
<!--      </div>-->
<!--      <div class='inputText'>-->
<!--        <input type="text" @click="inputText1"/>-->
<!--        <div class="placehol" v-if="show1">Please enter your name</div>-->
<!--      </div>-->
<!--      <div class='inputText'>-->
<!--        <input type="text" @click="inputText2"/>-->
<!--        <div class="placehol" v-if="show2">Please enter the payment amount</div>-->
<!--      </div>-->
<!--      <div class="bf_foot">-->
<!--        If you use a friend's account to recharge,-->
<!--        please fill in your friend's real name and CPF,-->
<!--        otherwise the name and CPF of the payment account are inconsistent with the real-->
<!--        content, and the recharge will not be recognized-->
<!--      </div>-->
<!--    </div>-->
    <button>Ir para pagar</button>
  </div>
</template>

<script>
export default {
  name: "saveRollInfo",
  data() {
    return {
      show1: false,
      show2: false,
    }
  },
  methods: {
    inputText1() {
      this.show1 = true;
    },
    inputText2() {
      this.show2 = true;
    }
  }
}
</script>

<style>
.content_tt {
  background-color: rgba(200, 181, 245, 0.2);
  height: 100vh;
  padding: 30Px 20Px;
}

.tcheaderCT, .tcheader {
  /* align-items:center; */
  box-shadow: 2Px 2Px 10Px rgba(200, 181, 245, 0.3);
  border-radius: 10Px;
  padding: 20Px;
  margin: 20Px 0Px;
  /* justify-content: flex-start; */
}

.tcheaderCT {
  display: flex;
}

.tcheaderCT > div:nth-child(1) {
  padding-right: 100Px;
  font-size: 26Px;
  color: #724ec6;
  text-align: center;
}

.tcheader > div:nth-child(1) {
  font-size: 35Px;
  color: #724ec6;
}

.sxc {
  display: flex;
  align-items: center;
}

.ty1 {
  font-size: 40Px;
  font-weight: 300;
}

.ty {
  font-size: 50Px;
  font-weight: 700;
  padding: 10Px;
  text-align: center;
}

.ty span {
  font-size: 30Px;
  margin-right: 10Px;
}

.inputText {
  padding: 10Px 20Px;
  border: 1Px solid rgba(123, 24, 169, 0.8);
  border-radius: 5Px;
  margin: 10Px 0Px 30Px;
  position: relative;
  display: flex;
  align-items: center;
}

.inputText input {
  width: 340Px;
}

.inputText::before {
  content: "";
  width: 20Px;
  height: 20Px;
  border-radius: 50%;
  background-color: #da0e73;
  position: absolute;
  right: 38%;
  top: 50%;
  transform: translateY(-50%);
}

.placehol {
  width: 200Px;
  padding: 5Px 10Px;
  font-size: 20Px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 8Px;
  margin-left: 30Px;
  position: relative;
}

.placehol::after {
  content: "";
  position: absolute;
  width: 0Px;
  height: 0Px;
  /* background-color:rgba(0,0,0,0.7); */
  border-top: 10Px solid transparent;
  border-bottom: 10Px solid transparent;
  border-right: 10Px solid rgba(0, 0, 0, 0.7);
  /* background-color: #724ec6; */
  position: absolute;
  top: 50%;
  left: -10Px;
  transform: translateY(-50%);

}

.inputText input {
  padding: 10Px 20Px;
}

.bf_foot {
  font-size: 30Px;
  color: #000;
  margin: 10Px 0Px 20Px;
}

button {
  background-color: #724ec6;
  color: #fff;
  padding: 5Px 10Px;
  margin: 60Px 0Px;
}
</style>
